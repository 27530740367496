@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;700&display=swap');

body {
    font-family: 'Orbitron', sans-serif;
    /* font-size: 16px; */
}

.login {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.login-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .75);
    z-index: 100;
}

.login-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .75);
    color: white;
    display: flex;
    justify-content: flex-end;
}

.heading-wrapper {
    color: red;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 80px;
}

.input-wrapper {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
}

.input-data-container {
    width: 400px;
}

.input-desc {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 2px;
    left: 6px;
}

.input-wrapper input, .input-wrapper select {
    width: 100%;
    background-color: rgba(0, 0, 0, .75);
    border: 2px solid red !important;
    outline: none !important;
    color: red;
    padding: 35px 20px 20px 20px;
    font-size: 18px;
}

.input-wrapper select {
    padding: 0;
}

.input-wrapper select option {
    color: red;
}

h2 {
    color: #fff;
}

hr {
    border-color: #2c2e33 !important;
}

#wrapper,
.navbar-side,
.sidebar-collapse .nav > li > a,
.navbar-cls-top {
    background-color: #191C24;
}

#page-wrapper,
#page-inner {
    background-color: black !important;
}

.panel-body,
.panel-default > .panel-heading {
    background-color: #191C24;
    color: #6c7293;
}

.panel-heading,
.panel-default {
    border-color: #2c2e33 !important;
}

.table-bordered,
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    border: 2px solid #2c2e33;
}

.table-responsive {
    overflow-x: auto;
}
/* color: #6c7293; */

.input-label {
    color: #fff;
    font-size: 12px;
    margin: 8px 0;
    padding: 2px;
}


.version-form {
    margin: 40px 5px;
}

.add-video-btn {
    margin-left: 35%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.m-20 {
    margin: 20px;
}

.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 70%;
    top: 90px;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.video-upload-status {
    margin-top: -35px;
    margin-bottom: 20px;
    text-align: center;
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
}

@keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
}

.video-upload-status-text {
    color: red;
    font-size: 1.5rem;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

.delete {
    color: red;
    cursor: pointer;
    padding-right: 10px;
}

.base-fa {
    cursor: pointer;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    background-color: white;
}

iframe {
    display: none !important;
    z-index: -1 !important;
}