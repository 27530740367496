
/*=============================================================
    Authour URI: www.binarycart.com
    License: Commons Attribution 3.0

    http://creativecommons.org/licenses/by/3.0/

    100% To use For Personal And Commercial Use.
    IN EXCHANGE JUST GIVE US CREDITS AND TELL YOUR FRIENDS ABOUT US

    ========================================================  */
/*==============================================
    GENERAL  STYLES
    =============================================*/
body {
    font-family: 'Open Sans', sans-serif;
}

 #wrapper {
    width: 100%;
    background:#202020;
}

#page-wrapper {
    padding: 15px 15px;
    min-height: 600px;
    background:#F3F3F3;

}
#page-inner {
    width:100%;
    margin:10px 20px 10px 0px;
    background-color:#fff!important;
    padding:10px;
    min-height:1200px;
}

.text-center {
    text-align:center;
}
.no-boder {
    border:1px solid #f3f3f3;
}
h2 {
    color: #f00;
}
h4 {
    padding-top:10px;
}
.square-btn-adjust {
    border: 0px solid transparent;
   -webkit-border-radius: 0px;
-moz-border-radius: 0px;
border-radius: 0px;

}
p {
    font-size:16px;
    line-height:25px;
    padding-top:20px;
}
/*==============================================
   DASHBOARD STYLES
    =============================================*/
.panel-back {
    background-color:#F8F8F8;

}
   .noti-box {
min-height: 100px;
padding: 20px;
}

    .noti-box .icon-box {
display: block;
float: left;
margin: 0 15px 10px 0;
width: 70px;
height: 70px;
line-height: 75px;
vertical-align: middle;
text-align: center;
font-size: 40px;
}
.text-box p{
    margin: 0 0 3px;
}
.main-text {
    font-size: 25px;
    font-weight:600;
}
.set-icon {
    -webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 50px;

}
    .bg-color-green {
background-color: #00CE6F;
color: #fff;
}
 .bg-color-blue {
background-color: #A95DF0;
color: #fff;
}
  .bg-color-red {
background-color: #DB0630;
color: #fff;
}
  .bg-color-brown {
background-color: #B94A00;
color: #fff;
}


 .icon-box-right {
display: block;
float: right;
margin: 0 15px 10px 0;
width: 70px;
height: 70px;
line-height: 75px;
vertical-align: middle;
text-align: center;
font-size: 40px;
}

 .main-temp-back {
background: #8702A8;
color: #FFFFFF;
font-size: 16px;
font-weight: 300;
text-align: center;
}
 .main-temp-back .text-temp {
font-size: 40px;
}
.back-dash {
    padding:20px;
    font-size:20px;
    font-weight:500;
      -webkit-border-radius: 0px;
-moz-border-radius: 0px;
border-radius: 0px;
background-color:#2EA7EB;
color:#fff;
}
    .back-dash p {
        padding-top:16px;
        font-size:13px;
        color:#fff;
        line-height:25px;
        text-align:justify;
    }
    .back-footer-green {
    background-color: #009B50;
    border-top: 0px solid #fff;
}
     .back-footer-red {
    background-color: #AF0000;
    border-top: 0px solid #fff;
}
     .color-bottom-txt {
   color: #000;
font-size: 16px;
line-height: 30px;
}
     /*CHAT PANEL*/
 .chat-panel .panel-body {
height: 450px;
overflow-y: scroll;
}
 .chat-box {
margin: 0;
padding: 0;
list-style: none;
}
 .chat-box li {
margin-bottom: 15px;
padding-bottom: 5px;
border-bottom: 1px dotted #808080;
}
 .chat-box li.left .chat-body {
margin-left: 90px;
}
 .chat-box li .chat-body p {
margin: 0;
color: #8d8888;
}
.chat-img>img {
    margin-left:20px;
}

/*==============================================
    MENU STYLES
    =============================================*/


.user-image {
    margin: 25px auto;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
max-height:170px;
max-width:170px;
}

.navbar-cls-top .navbar-brand {
	color: #fff;
background: #C90000;
width: 260px;
text-align: center;
height: 60px;
font-size: 30px;
font-weight: 700;
}
.active-menu {
    background-color:#C90000!important;
}

.arrow {
    float: right;
}

.fa.arrow:before {
    content: "\f104";
}

.active > a > .fa.arrow:before {
    content: "\f107";
}


.nav-second-level li,
.nav-third-level li {
    border-bottom: none !important;
}

.nav-second-level li a {
    padding-left: 37px;
}

.nav-third-level li a {
    padding-left: 55px;
}
.sidebar-collapse , .sidebar-collapse .nav{
	background:none;
}
.sidebar-collapse .nav {
	padding:0;
}
.sidebar-collapse .nav > li > a {
	color:#fff;
	background:#202020;
	text-shadow:none;

}
.sidebar-collapse > .nav > li > a,
.sidebar-collapse > .nav > li > div.holder {
	padding:15px 10px;
}
.sidebar-collapse > .nav > li {
	border-bottom: 1px solid rgba(107, 108, 109, 0.19);
}
.sidebar-collapse .nav > li > a:hover,
.sidebar-collapse .nav > li > a:focus {

	background:#0A0A0A;
	outline:0;
}

.nav-second-level > li > a:before ,
.nav-third-level > li > a:before {
	content:"";
	display:block;
	position:absolute;
	left:20px;
	width:15px;
	height:1px;
	background:#ff0000;
	top:20px;
}
.nav-second-level > li > a:after,
.nav-third-level > li > a:after {
	content:"";
	display:block;
	position:absolute;
	left:20px;
	width:1px;
	height:100%;
	background:#ff0000;
	top:0px;
	z-index:10;
}
.nav-second-level  > li:last-child > a:after,
.nav-third-level  > li:last-child > a:after {
	height:50%;
}
.nav-third-level > li > a:after,
.nav-third-level > li > a:before  {
	left:40px;
}
.navbar-side {
	border:none;
	background-color: #202020;

}
.navbar-cls-top {
	background:#4D4D4D;
	border-bottom:none;

}
.navbar-cls-top .navbar-brand:hover {

background: #C90000;
color:#fff;

}

.navbar-default {
border:0px solid black;

}
.navbar-header {
    background: #A70303;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
background-color: #B40101;
}
.navbar-default .navbar-toggle {
border-color: #fff;
}

.navbar-default .navbar-toggle .icon-bar {
background-color: #FFF;
}
.nav > li > a > i {
    margin-right:10px;
}

.promotion_details > div > label {
    color: lightgray;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
}

.promotion_details > div > span {
    color: white;
    font-weight: 600;
    font-size: 16px;
}

.mr-5 {
    margin-right: 5px;
}
/*==============================================
    UI ELEMENTS STYLES
    =============================================*/
.btn-circle {
width: 50px;
height: 50px;
padding: 6px 0;
 -webkit-border-radius: 25px;
-moz-border-radius: 25px;
border-radius: 25px;
text-align: center;
font-size: 12px;
line-height: 1.428571429;
}

/*==============================================
    MEDIA QUERIES
    =============================================*/

 @media(min-width:768px) {
     #page-wrapper{
               margin: 0 0 0 260px;
        padding: 15px 30px;
        min-height: 1200px;

    }


    .navbar-side {
        z-index: 1;
        position: absolute;
        width: 260px;
    }

   .navbar {
 border-radius: 0px;
}

}


.copy-icon {
    width: 40px;
    height: 30px;
    cursor: pointer;
}

.copy-icon svg {
    width: 100%;
    height: 100%;
    fill: #fff;
}

.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 64px;
    height: 64px;
    opacity: 1;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: red;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  